selectContents = function(element) {
    if (window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
        var range = document.createRange();
        range.selectNodeContents(element);
        sel.addRange(range);
    } else if (document.selection) {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(element);
        textRange.select();
    }
}
$( document ).ready(function() {

    var $body = $('body');
    var $formExerciseAnswerForm = $('#exerciseAnswerForm');

    var $submitAnswer = $('#submitAnswer');
    var $metaQuestions = $("#metaQuestions");
    var $metaQuestionsInput = $("#metaQuestionsInput");
    var $answerResponse = $('#answerResponse');
    var $nextExerciseBtn = $('#nextExerciseBtn');

    var $exerciseHelpBtn = $('#exerciseHelpBtn');
    var $exerciseTypeDescription = $('#exerciseDescriptionWrp');
    var $categoryShift = $('#categoryShift');
    var $courseStartUp = $('#courseStartUp');
    var $overlay = $('#overlay');
    var $svgLoader = $('#svgLoader');
    var $chessBoardHelp = $('#chessBoardHelp');

    var $consciousCorrect = $('#consciousCorrect');
    var $unConsciousCorrect = $('#unConsciousCorrect');
    var $consciousInCorrect = $('#consciousInCorrect');
    var $unConsciousInCorrect = $('#unConsciousInCorrect');

    var metaAnswerTypes = ['consciousCorrect', 'unConsciousCorrect', 'consciousInCorrect', 'unConsciousInCorrect'];

    $exerciseHelpBtn.on( "click", function() {
        $exerciseTypeDescription.removeClass('hidden');
    });

    /* overlay - remove */
    $overlay.on( "click", function() {
        $(this).addClass('hidden');
        $chessBoardHelp.addClass('hidden');
        $body.removeClass('modal-open');
        if($courseStartUp[0] && !$courseStartUp.hasClass('hidden')){
            $courseStartUp.addClass('hidden');
        }else{
            $exerciseTypeDescription.addClass('hidden')
        }
        if($exerciseTypeDescription.hasClass('hidden')){
            $(':input[type="number"]:enabled:visible').filter(function() { return this.value == ""; }).first().focus();
        }
    });

    $exerciseTypeDescription.on( "click", function() {$overlay.click();});

    /* submit - answer */
    $submitAnswer.on('click', function(){
        $overlay.removeClass('hidden');
        $body.addClass('modal-open');
        return false;
    });

    submitAnswerProcess = function(){

        if (typeof ajaxRequest != 'undefined') {
            ajaxRequest.abort();
        }
        var data = $formExerciseAnswerForm.serializeArray();


        ajaxRequest = $.ajax({
            type: "post",
            url: ( (window.location.href.indexOf("test") > -1) ? '/ajax/test-solve-exercise-process' : '/student/ajax/solve-exercise-process'),
            data: data,
            dataType: 'json'
        }).done(function (response, textStatus, jqXHR){
            $svgLoader.addClass('hidden');
            console.log(response);
            $metaQuestions.find('a').removeAttr("onclick");
            var metaAnswer = $('#metaQuestionsInput').val();
            if(response.status == 'SESSION_RUN_OUT'){
                var ask = window.confirm("Din session er udløbet!");
                if (ask){
                    document.location.href = "/";
                }else{
                    close();
                    return false;
                }
            }else if(response.status == 'OK'){
                if(response.correctAnswer == true){
                    $answerResponse.find('#correct').removeClass('hidden');
                    $('#'+metaAnswerTypes[metaAnswer < 2 ? 0 : 1]).removeClass('hidden');
                    setTimeout(function() { window.location.reload(true); }, 4000);
                }else if(response.correctAnswer == false){
                    if (typeof showCorrectAnswer == 'function') {
                        showCorrectAnswer();
                    }
                    $answerResponse.find('#wrong').removeClass('hidden');

                    //$answerResponse.on('click', function(){ window.location.reload(true); }).css({'cursor':'pointer'});
                    $nextExerciseBtn.removeClass('hidden');
                    $('#'+metaAnswerTypes[metaAnswer > 2 ? 2 : 3]).removeClass('hidden');
                    var secondsToShowCorrectAnswer = $("#secondsToShowCorrectAnswer").val();
                    setTimeout(function() { window.location.reload(true); }, secondsToShowCorrectAnswer*1000);
                }
                $answerResponse.removeClass('hidden');
            }else{

                console.log(response);
                var ask = window.confirm("Der er sket en fejl. Vil du reloade siden?" + response);
                if (ask){
                    window.location.reload(true);
                }else{
                    close();
                    return false;
                }
               // console.log(response);
            }
        }).fail(function (jqXHR, textStatus, errorThrown){
            //console.log(jqXHR);
            var responseText = ajaxRequest.responseText;
            console.log(responseText);
           // console.log(textStatus);
            //console.log(errorThrown);
            var ask = window.confirm("Der er sket en fejl. Vil du reloade siden?");
            if (ask){
                window.location.reload(true);
            }else{
                close();
                return false;
            }
        }).always(function () {
            $body.css('cursor','default');
        });
    };

    submitAnswer = function(metaAnswer){
        $body.css('cursor','wait').addClass('modal-open');
        $overlay.unbind('click').removeClass('hidden');
        $svgLoader.removeClass('hidden');
        $metaQuestionsInput.val(metaAnswer);
        submitAnswerProcess();
    };

    $("input.sum").on('keyup', function (e) {
        if (e.keyCode == 13) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    });

    var $cycleImages = $('#cycleImages');
    if($cycleImages[0]){
        var imgNr = parseInt($cycleImages.data('img-nr'));
        var $oldImg = null;
        var images = $cycleImages.data('cycle-images');
        function changeBackground(){
            var oldImgNr = imgNr;
            imgNr = imgNr+1 <= images.length ? imgNr+1 : 1;
            $oldImg = $cycleImages.find('#img_'+oldImgNr);
            var $newImg = $cycleImages.find('#img_'+imgNr);
            $newImg.css({'z-index':'2'}).fadeIn( 2000, function(){
                $(this).css({'z-index':'1'});
                $oldImg.css({'z-index':'0'}).hide();
            });
        }
        if(images instanceof Array){
            var path = '/assets/img/home-images/';
            var imgLoaded = 1;
            for(var i=0; i < images.length; i++){
                if(i+1 != imgNr){
                    $('<img src="'+ path + images[i].img +'" class="cycle-img" id="img_'+(i+1)+'">').load(function() {
                        $(this).hide().appendTo($cycleImages);
                        imgLoaded++;
                        if(imgLoaded == images.length){
                            setInterval(changeBackground, 6000);
                        }
                    });
                }
            }
        }
    }

});